import { useEffect, useState } from "react"
import { Campaign } from "../../types"
import { getLiveDateRange } from "../../utils/helpers"

function secondsToDhms(seconds: number) {
  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor(seconds % 60)

  return {
    dayTime: String(d >= 0 ? (d >= 10 ? d : "0" + d) : ""),
    hourTime: String(h >= 0 ? (h >= 10 ? h : "0" + h) : ""),
    minuteTime: String(m >= 0 ? (m >= 10 ? m : "0" + m) : ""),
    secondsTime: String(s >= 0 ? (s >= 10 ? s : "0" + s) : "")
  }
}

interface CounterTime {
  dayTime: string
  hourTime: string
  minuteTime: string
  secondsTime: string
}

export default function Countdown({ campaign }: { campaign: Campaign }) {
  const [counterTime, setCounterTime] = useState<CounterTime | null>(null)

  const range = getLiveDateRange(campaign.dates)
  const today = new Date()
  const endDate = new Date(range.ends)

  const diffInTime = endDate.getTime() - today.getTime()
  const diffInDays = Math.round(diffInTime / (1000 * 3600 * 24))

  useEffect(() => {
    const int = setInterval(() => {
      const now = new Date()
      const diff = Math.floor((Date.parse(range.ends) - now.getTime()) / 1000)

      setCounterTime(secondsToDhms(diff))
    }, 1000)

    return () => clearInterval(int)
  })

  if (diffInDays >= 30 || !counterTime) {
    return null
  }

  return (
    <>
      <div className="countdown-holder">
        <h2>Offer Ends In</h2>
        <div id="countdown-form" className="counter">
          <span className={counterTime.dayTime === "1" ? "day" : "days"}>
            {counterTime.dayTime}
          </span>
          <span className={counterTime.hourTime === "1" ? "hour" : "hours"}>
            {counterTime.hourTime}
          </span>
          <span
            className={counterTime.minuteTime === "1" ? "minute" : "minutes"}
          >
            {counterTime.minuteTime}
          </span>
          <span
            className={counterTime.secondsTime === "1" ? "second" : "seconds"}
          >
            {counterTime.secondsTime}
          </span>
        </div>
      </div>
    </>
  )
}
