import { createRef, useContext, useEffect, useState } from "react"
import { useRouter } from "next/router"
import { Form, Formik } from "formik"
import ReCAPTCHA from "react-google-recaptcha"
import cookieCutter from "cookie-cutter"
import * as Sentry from "@sentry/nextjs"
import { useSelector } from "@xstate/react"
import { BluefinRes, Campaign, CampaignFormValues } from "../../types"
import PrivacyPolicy from "../PrivacyPolicy"
import Footer from "../Footer"
import Share from "../Share"
import { PaymentiFrame } from "../../lib/bluefin-iframe-lib-1.0.0"
import { useTracking } from "../../contexts/trackers"
import {
  alertUser,
  getProductCodes,
  minimumMinistryPartnerDonation,
  isUSTerritory
} from "../../utils/helpers"
import { ServiceContext } from "../../pages/[id]/[slug]"
import {
  DonationAmountContext,
  ProductCodesContext,
  RecurringContext
} from "../../machines"
import { cleanAmount } from "../../utils/helpers"
import Fields from "./Fields"
import CampaignDetails from "./CampaignDetails"
import CampaignOfferProduct from "./CampaignOfferProduct"
import { HiddenInputFields } from "./HiddenInputFields"
import { CampaignHeader } from "./CampaignHeader"
import { Login } from "../Login"
import { XStateContext } from "../../contexts/XStateCtx"
import { NEW_PAYMENT_METHOD } from "./Payment"
import { UserData } from "../../pages/api/user"
import { useDonationAmount } from "../../hooks/useDonationAmount"
import { format } from "date-fns"

const LIG_PP_COOKIE = "___ligonier_pp"

type DetailsProps = {
  campaign: Campaign
  userData: UserData
}

const paymentMethodsServiceSelector = ({ context }) => {
  return {
    userPaymentMethods: context.userPaymentMethods,
    selectedPaymentMethod: context.selectedPaymentMethod
  }
}

const initialValues: CampaignFormValues = {
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
  address: {
    country: "",
    state: "",
    city: "",
    address1: "",
    address2: "",
    postal_code: ""
  },
  product_codes: [],
  // Even for free campaigns, a donation object MUST be supplied
  // to the API
  donation: {
    amount: "0.00",
    ministry_partner: false,
    now: true,
    recurring: false
  }
}

export default function Details({ campaign, userData }: DetailsProps) {
  const xStateServices = useContext(XStateContext)
  const paymentMethodsContext = useSelector(
    xStateServices.paymentMethodsService,
    paymentMethodsServiceSelector
  )
  const router = useRouter()
  const {
    ga,
    setUpGoogleAdServices,
    trackFormStartedEvent,
    isFormStartedEventTracked
  } = useTracking()

  const { donationAmount, oneTime } = router.query
  const { donationAmountService, recurringService, productCodesService } =
    useContext(ServiceContext)

  const [gaClientId, setGaClientId] = useState("")
  const [paymentiFrame, setPaymentiFrame] = useState(null)
  const [isPrivacyPolicyModalVisible, setIsPrivacyPolicyModalVisible] =
    useState(false)
  // form fields relating to paid campaigns
  const [donationAmountContext, setDonationAmountContext] =
    useState<DonationAmountContext | null>(null)
  const [recurringContext, setRecurringContext] =
    useState<RecurringContext | null>(null)
  const [productCodesContext, setProductCodesContext] =
    useState<ProductCodesContext | null>(null)

  const { donationTodayAmount: amount, recurringGiftAmount: recurring_amount } =
    useDonationAmount()

  const isFree = campaign?.offer?.free
  const urlAmount = donationAmount || oneTime

  // The amount to charge is derived from either
  // A set recurring amount, a user provided donationAmount
  // or the donation amount selected from the list of amounts

  const recaptchaRef = createRef<{ executeAsync: () => Promise<string> }>()

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      setUpGoogleAdServices()
    }
  }, [setUpGoogleAdServices])

  const isNewPaymentMethodSelected =
    paymentMethodsContext.selectedPaymentMethod === NEW_PAYMENT_METHOD

  useEffect(() => {
    if (!isFree || !campaign.offer) {
      // The PaymentiFrame comes from the bluefin library which lives in a .js file in this project
      // we type it here to avoid a TS issue when we call the constructor below
      const PiFrameConstructor: ({ create, iframeId }) => void = PaymentiFrame

      setPaymentiFrame(
        new PiFrameConstructor({
          create: false,
          iframeId: "payment_iframe"
        })
      )
    }
  }, [isFree, campaign.offer])

  // we set the client id for form submission
  useEffect(() => {
    ga((tracker) => {
      setGaClientId(tracker.get("clientId"))
    })
  }, [ga])

  useEffect(() => {
    const donationSubscription = donationAmountService.subscribe((state) => {
      setDonationAmountContext(state.context)
    })

    const recurringSubscription = recurringService.subscribe((state) => {
      setRecurringContext(state.context)
    })

    const productCodesSubscription = productCodesService.subscribe((state) => {
      setProductCodesContext(state.context)
    })

    return () => {
      donationSubscription.unsubscribe()
      recurringSubscription.unsubscribe()
      productCodesSubscription.unsubscribe()
    }
  }, [donationAmountService, recurringService, productCodesService])

  const onPrivacyPolicyAccept = (handleSubmit) => {
    // we set a cookie...
    cookieCutter.set(LIG_PP_COOKIE, "true", {
      // ...expiring 1 year from now
      expiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    })
    // we track the event
    ga("send", "event", "PrivacyPolicyModal", "accepted")
    // then close the modal and resubmit the form
    setIsPrivacyPolicyModalVisible(false)
    // handleSubmit is passed as a callback from Formik and called here
    handleSubmit()
  }

  const onPrivacyPolicyDecline = () => {
    ga("send", "event", "PrivacyPolicyModal", "declined")
    setIsPrivacyPolicyModalVisible(false)
  }

  const onValidate = () => {
    if (!isFormStartedEventTracked) trackFormStartedEvent(campaign)
  }

  const verifyCaptcha = async (): Promise<boolean> => {
    // start with captcha verification
    try {
      const token = await recaptchaRef.current.executeAsync()

      // now we have a token, let's verify
      const rawCaptchaResponse = await fetch(`/api/recaptcha/verify/${token}`, {
        method: "POST"
      })

      const captchaResponse = await rawCaptchaResponse.json()

      // pass captcha if we're not in production
      return captchaResponse.valid || process.env.NODE_ENV !== "production"
    } catch (error) {
      console.error("Recaptcha error", error)
      Sentry.captureException(error)

      return false
    }
  }

  const privacyPolicyCheck = () => {
    const privacyCookie = cookieCutter.get(LIG_PP_COOKIE)

    if (campaign.language === "en-US" && privacyCookie !== "true") {
      setIsPrivacyPolicyModalVisible(true)

      return false
    } else {
      return true
    }
  }

  const amountSelectedCheck = () => {
    if (!Number(amount) && !isFree) {
      alert("Please select or enter an amount")

      return false
    } else {
      return true
    }
  }

  const preSubmissionChecks = () => {
    const isPPCheckPassed = privacyPolicyCheck()
    const amountSelectedPassed = amountSelectedCheck()

    return isPPCheckPassed && amountSelectedPassed
  }

  const handleTransactionCreateError = async (result, setFieldError) => {
    const response = await result.json()
    const nonFieldErrors = response.non_field_errors

    const forNewPeople =
      "We're sorry, this offer is for those new to Ligonier Ministries."
    const userHasAlready = "You have already received this offer."
    const seCityError = "We have not been able to validate your City"
    const cityError =
      "Address verification failed to recognize the city you entered"

    if (nonFieldErrors) {
      if (nonFieldErrors.includes(forNewPeople)) {
        alert(forNewPeople)
      } else if (nonFieldErrors.includes(userHasAlready)) {
        alert(userHasAlready)
      } else if (nonFieldErrors.includes(seCityError)) {
        alert(cityError)
      } else {
        // Address not found.
        alert(JSON.stringify(nonFieldErrors))
      }
    }
    // use the error object to set errors for the corresponsing fields
    else {
      if (Object.keys(response).length) {
        const fieldsWithErrorsArray = Array.from(Object.keys(response))

        fieldsWithErrorsArray.forEach((fieldWithError) => {
          if (fieldWithError === "address") {
            if (campaign.language === "es-US") {
              alert(
                "Esta dirección no es válida. No encontramos ninguna calle con dicho nombre en esa ciudad o código postal."
              )
            } else {
              if (response[fieldWithError][0] === "0") {
                alert(response[fieldWithError][0])
              } else {
                const addressFields = Array.from(
                  Object.keys(response[fieldWithError])
                )
                if (Array.isArray(response[fieldWithError])) {
                  response[fieldWithError]?.forEach((error) => {
                    setFieldError(fieldWithError, error)
                  })
                } else {
                  addressFields.forEach((nestedField) => {
                    setFieldError(
                      nestedField,
                      response[fieldWithError][nestedField]
                    )
                  })
                }
              }
            }
          } else if (fieldWithError === "credit_card") {
            if (campaign.language === "es-US") {
              alert("Por favor verifica los datos de tu tarjeta")
            } else {
              alert(response.credit_card.join(" "))
            }
          } else if (Array.isArray(response[fieldWithError])) {
            setFieldError(fieldWithError, response[fieldWithError][0])
          } else {
            // This is a nested field error
            const nestedFieldsWithErrorsArray = Array.from(
              Object.keys(response[fieldWithError])
            )

            nestedFieldsWithErrorsArray.forEach((nestedFieldWithError) => {
              setFieldError(
                `${fieldWithError}[${nestedFieldWithError}]`,
                response[fieldWithError][nestedFieldWithError]
              )
            })
          }
        })
      }
    }
  }

  const bluefinRequest = (): Promise<BluefinRes> =>
    new Promise((resolve, reject) => {
      paymentiFrame
        .encrypt()
        .failure(() => {
          alertUser({
            key: "BLUEFIN_PAYMENT_FAILURE",
            lang: campaign.language
          })

          reject()
        })
        .invalidInput(() => {
          alertUser({
            key: "BLUEFIN_INPUT_NOT_VALID",
            lang: campaign.language
          })

          reject()
        })
        .success(async (bluefinRes) => {
          resolve(bluefinRes)
        })
    })

  const createTransaction = async ({
    campaign,
    values: initialValues,
    bluefinRes,
    setFieldError
  }: {
    campaign: Campaign
    values: CampaignFormValues
    bluefinRes?: BluefinRes | null
    setFieldError: (field: string, error: string) => void
  }) => {
    let updatedValues = null

    // US Territories are formatted like US states for mailing purposes
    if (
      campaign.capture_address &&
      isUSTerritory(initialValues.address?.country)
    ) {
      updatedValues = {
        ...initialValues,
        address: {
          ...initialValues.address,
          country: "US",
          state: initialValues.address.country
        }
      }
    }

    const values = updatedValues || initialValues

    // delete address and phone fields from the form values if the campaign
    // specifies not capturing this data
    !campaign.capture_phone && delete values.phone
    !campaign.capture_address && delete values.address

    try {
      let transactionData = {
        campaign_id: campaign.id,
        ga_cid: gaClientId,
        ...values,
        ...(!values.product_codes.length &&
          getProductCodes(campaign).length > 0 && {
            product_codes: getProductCodes(campaign)
          })
      }

      if (!isFree) {
        const allowMinistryPartner =
          Number(recurring_amount) >= minimumMinistryPartnerDonation

        const userSelectedProducts = productCodesContext.product_codes.length

        const transactionPaymentData = isNewPaymentMethodSelected
          ? {
              credit_card: {
                card_number: bluefinRes.masked.number,
                expiration_month: bluefinRes.masked.expy.substring(0, 2),
                expiration_year: "20" + bluefinRes.masked.expy.substring(2, 4),
                etoken: bluefinRes.eToken
              }
            }
          : {
              saved_payment_method: paymentMethodsContext.selectedPaymentMethod
              // user_auth_token: <We add the user_auth_token on the server as the Django API
              // requries this when transactions are submitted with the saved_payment_method field>
            }

        transactionData = {
          ...transactionData,
          // override inital donation object using recurring and donation contexts
          donation: {
            amount,
            ...((recurring_amount && recurringContext.values.recurring) && {
              recurring_amount
            }),
            ministry_partner:
              recurringContext.values.ministryPartnerChecked &&
              allowMinistryPartner,
            now: true,
            recurring: recurringContext.values.recurring,
            recur_on: recurringContext.values.recurOnDay,
            recur_start_date: format(recurringContext.values.recurStartDate, "yyyy-MM-dd")
          },
          // Only use the product_codes in context if the user selected products
          product_codes: userSelectedProducts
            ? productCodesContext.product_codes
            : transactionData.product_codes,
          ...transactionPaymentData
        }
      }

      // If there are no product codes, we delete the product_codes array
      // The Django server reject a campaign with an empty product_codes array
      // if the campaign has an offer.
      if (!transactionData.product_codes.length) {
        delete transactionData.product_codes
      }

      const result = await fetch(`/api/${campaign.id}/transactions`, {
        method: "POST",
        body: JSON.stringify(transactionData)
      })

      if (result.ok) {
        router.push(`/${campaign.id}/transaction`)
      } else {
        handleTransactionCreateError(result, setFieldError)
      }
    } catch (e) {
      alert("Something went wrong. Please try again")
      Sentry.captureException(e)
    }
  }

  const onSubmit = async (values, { setSubmitting, setFieldError }) => {
    const checksPassed = preSubmissionChecks()

    if (!checksPassed) {
      setSubmitting(false)

      return
    }

    if (isFree) {
      // If the campaign is free, we just move to the transaction creation
      await createTransaction({
        values,
        setFieldError,
        campaign
      })
    } else {
      // if it's not, we verify captcha, as well as hit the bluefin API
      // using the paymentiFrame library
      try {
        const isCaptchaValid = await verifyCaptcha()

        if (isCaptchaValid) {
          try {
            const bluefinRes = isNewPaymentMethodSelected
              ? await bluefinRequest()
              : null

            await createTransaction({
              bluefinRes,
              values,
              setFieldError,
              campaign
            })
          } catch (e) {
            console.error(e)
            Sentry.captureException(e)
            alertUser({
              key: "BLUEFIN_PAYMENT_FAILURE",
              lang: campaign.language
            })
          }
        } else {
          alertUser({ key: "CAPTCHA_NOT_VALID", lang: campaign.language })
        }
      } catch (e) {
        console.error(e)
        Sentry.captureException(e)
        alertUser({ key: "CAPTCHA_NOT_VALID", lang: campaign.language })
      }
    }
  }

  return (
    <>
      <CampaignHeader campaign={campaign} />
      <main>
        <Formik
          initialValues={initialValues}
          validate={onValidate}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid
          }) => {
            return (
              <>
                <Form className="form-layout" id="gift" autoComplete="on">
                  <HiddenInputFields
                    campaign={campaign}
                    urlAmount={urlAmount}
                    recurringSelected={recurringContext?.values?.recurring}
                  />
                  <CampaignOfferProduct campaign={campaign} />
                  <div className="container">
                    <div className="row">
                      <CampaignDetails campaign={campaign} />
                      <div id="form-inputs" className="secondary-col">
                        <div className="sticky-holder">
                          {!campaign?.offer?.new_users_only && (
                            <Login
                              userData={userData}
                              isEnglish={campaign.language !== "es-US"}
                              isCampaignForNewUsersOnly={
                                campaign?.offer?.new_users_only
                              }
                            />
                          )}
                          <Fields
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            campaign={campaign}
                            isFormValid={isValid}
                            isSubmitting={isSubmitting}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
                <PrivacyPolicy
                  isVisible={isPrivacyPolicyModalVisible}
                  onAccept={() => onPrivacyPolicyAccept(handleSubmit)}
                  onDecline={onPrivacyPolicyDecline}
                />
              </>
            )
          }}
        </Formik>
      </main>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY}
      />
      <Share campaign={campaign} />
      <Footer campaign={campaign} />
    </>
  )
}
