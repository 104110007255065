import { useContext, useEffect, useState } from "react"
import { DonationAmountContext, RecurringContext } from "../machines"
import { cleanAmount } from "../utils/helpers"
import { ServiceContext } from "../pages/[id]/[slug]"
import { minimumMinistryPartnerDonation } from "../utils/helpers"

export const useDonationAmount = () => {
  const { donationAmountService, recurringService, productCodesService } =
    useContext(ServiceContext)

  const [donationAmountContext, setDonationAmountContext] =
    useState<DonationAmountContext | null>(null)
  const [recurringContext, setRecurringContext] =
    useState<RecurringContext | null>(null)

  useEffect(() => {
    const donationSubscription = donationAmountService.subscribe((state) => {
      setDonationAmountContext(state.context)
    })

    const recurringSubscription = recurringService.subscribe((state) => {
      setRecurringContext(state.context)
    })
    return () => {
      donationSubscription.unsubscribe()
      recurringSubscription.unsubscribe()
    }
  }, [donationAmountService, recurringService, productCodesService])

  const oneTimeGiftAmount = cleanAmount(
    donationAmountContext?.donationAmount ||
      donationAmountContext?.donationAmountSelected
  )
  const hasNonzeroOneTimeGiftAmount = !!parseFloat(oneTimeGiftAmount)
  const isRecurring = recurringContext?.values?.recurring
  const recurOnDay = recurringContext?.values?.recurOnDay
  const recurStartDate = recurringContext?.values?.recurStartDate
  const recurringGiftAmount = cleanAmount(recurringContext?.values?.amount)
  const hasNonzeroRecurringGiftAmount = !!parseFloat(recurringGiftAmount)
  const isRecurringAndHasValue = isRecurring && hasNonzeroRecurringGiftAmount

  const isDonating = hasNonzeroOneTimeGiftAmount || isRecurringAndHasValue
  const combinedGiftAmountInt = Number(recurringGiftAmount) + Number(oneTimeGiftAmount)

  const totalGiftAmount = isRecurring ? String(combinedGiftAmountInt) : oneTimeGiftAmount

  const isMinistryPartnerAndHasValue = isRecurring && parseInt(recurringGiftAmount) >= minimumMinistryPartnerDonation && recurringContext?.values?.ministryPartnerChecked

  return {
    oneTimeGiftAmount: oneTimeGiftAmount,
    donationTodayAmount: totalGiftAmount,
    isRecurring,
    isRecurringAndHasValue,
    recurOnDay,
    recurStartDate,
    recurringGiftAmount,
    isDonating,
    isMinistryPartnerAndHasValue,
  }
}
