import { useContext, useEffect, useState } from "react"
import { useRouter } from "next/router"
import ReactHtmlParser from "react-html-parser"
import { getCopy, getHeadline } from "../../utils/helpers"
import { ServiceContext } from "../../pages/[id]/[slug]"
import Recurring from "./Recurring"
import NoCampaignOffer from "./NoCampaignOffer"
import ValidCampaignOffer from "./ValidCampaignOffer"
import ValuePropCampaignOffer from "./ValuePropCampaignOffer"

function ResourceDonationElement({ campaign }) {
  const [context, setContext] = useState(null)
  const { donationAmountService } = useContext(ServiceContext)
  const [customAmount, setCustomAmount] = useState("")

  useEffect(() => {
    const subscription = donationAmountService.subscribe((state) => {
      setContext(state.context)
    })

    return subscription.unsubscribe
  }, [donationAmountService])

  const router = useRouter()
  // We sanitize the URL amount.
  let urlAmount = (
    (router.query.oneTime || router.query.amount || "") as string
  )?.replace("$", "")

  useEffect(() => {
    if (
      context?.donationAmount === null &&
      context?.donationAmountSelected === null &&
      urlAmount
    ) {
      if (campaign.amounts?.includes(urlAmount)) {
        donationAmountService.send({
          type: "CHANGE",
          value: {
            donationAmountSelected: urlAmount as string,
            donationAmount: ""
          }
        })
      } else {
        // before setting the donationAmount let's add the '$'
        if (!urlAmount.startsWith("$")) {
          urlAmount = `$${urlAmount}`
        }
        donationAmountService.send({
          type: "CHANGE",
          value: {
            donationAmount: urlAmount as string,
            donationAmountSelected: ""
          }
        })
      }
    }
  }, [
    context?.donationAmount,
    context?.donationAmountSelected,
    urlAmount,
    donationAmountService,
    campaign.amounts
  ])

  return (
    <>
      <nav id="amounts">
        {campaign?.amounts?.map((amount) => {
          return (
            <a
              href="#"
              key={amount}
              className={
                amount === context?.donationAmountSelected ? "active" : ""
              }
              aria-label={`Choose ${amount} to donate`}
              onClick={(e) => {
                e.preventDefault()
                donationAmountService.send({
                  type: "CHANGE",
                  value: {
                    donationAmount: "",
                    donationAmountSelected: amount
                  }
                })
                setCustomAmount("")
              }}
            >
              {amount}
            </a>
          )
        })}
        <span id="input-wrap">
          {/*
          It looks like width-machine and the 99 is to determine the width on the input so it can continue to grow.
          It always stays with 2 digits to always have 2 digit width.
          */}
          <span id="width-machine" aria-hidden="true">
            999
          </span>
          <div>
            <label htmlFor="amount-input" className="sr-only">
              {campaign.language === "es-US" ? "Otro" : "Other"}
            </label>
            <input
              id="amount-input"
              name="amount-input"
              className={`amount ${
                context?.donationAmount && !campaign.amounts.includes(urlAmount)
                  ? "active"
                  : ""
              }`}
              type="tel"
              data-validation="money"
              onChange={(e) => {
                const value = e.target.value

                // Prevents user from trying to delete the $ symbol and prevents typing more than 2 decimal places
                if (!value.match(/^\$(\d+(\.\d{0,2})?)?$/)) {
                  return
                }

                if (value === "" || value.startsWith("$")) {
                  setCustomAmount(value)
                  donationAmountService.send({
                    type: "CHANGE",
                    value: {
                      donationAmount: value === "$" ? "" : value,
                      donationAmountSelected: ""
                    }
                  })
                }
              }}
              onFocus={() => {
                if (customAmount === "") {
                  setCustomAmount("$")
                }
              }}
              onBlur={() => {
                if (customAmount === "$") {
                  setCustomAmount("")
                }
              }}
              value={customAmount || context?.donationAmount || ""}
              placeholder={campaign.language === "es-US" ? "Otro" : "Other"}
            />
          </div>
        </span>
      </nav>
    </>
  )
}

export default function CampaignDetails({ campaign }) {
  const isFree = campaign.offer?.free

  return (
    <div className="primary-col">
      <h1 className="t1 center mb-4">{getHeadline(campaign)}</h1>
      {!isFree && <ResourceDonationElement campaign={campaign} />}
      {campaign.enable_recurring ? <Recurring campaign={campaign} /> : null}
      <p className="center ss2">{ReactHtmlParser(getCopy(campaign))}</p>
      {campaign.video ? (
        <>
          <div className="responsive-video mt-4">
            <iframe
              title="campaign video"
              src={campaign.video}
              width="640"
              height="360"
              frameBorder="0"
              allowFullScreen={true}
            ></iframe>
          </div>
        </>
      ) : null}
      {!campaign.offer && <NoCampaignOffer campaign={campaign} />}
      {campaign.offer && campaign.offer?.allowed_products && (
        <ValidCampaignOffer campaign={campaign} />
      )}
      {campaign.offer?.products.length && (
        <ValuePropCampaignOffer campaign={campaign} />
      )}{" "}
    </div>
  )
}
