import { useDonationAmount } from "../../hooks/useDonationAmount"
import { format } from "date-fns"

export const GiftSummary = () => {
  const {
    isDonating,
    oneTimeGiftAmount,
    donationTodayAmount,
    isRecurringAndHasValue,
    recurStartDate,
    recurringGiftAmount
  } = useDonationAmount()

  const startDateDisplay = recurStartDate ? format(recurStartDate, "MMM do") : ''

  return isDonating ? (
    <div className="gift-summary">
      <p id="gift-summary-title">
        <span>SUMMARY</span>
      </p>
      <div id="gift-summary-body">
        {donationTodayAmount && (
          <p>
            Your Gift Today: ${Number(donationTodayAmount).toFixed(2)}
            {isRecurringAndHasValue && (
              <>
              <br />
              {" "}(${Number(oneTimeGiftAmount).toFixed(2)} One Time gift
              and ${Number(recurringGiftAmount).toFixed(2)} Recurring Gift)
              </>
            )}
          </p>
        )}
        {isRecurringAndHasValue && (
          <p>
            Your Recurring Gift Beginning {startDateDisplay}: $
            {Number(recurringGiftAmount).toFixed(2)}
          </p>
        )}
      </div>
    </div>
  ) : null
}
