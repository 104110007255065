import { useContext, useEffect, useState } from "react"
import { connect, FormikContextType, FormikProps, FormikValues } from "formik"
import { useSession, signIn } from "next-auth/react"
import * as Sentry from "@sentry/nextjs"
import { useUserData } from "../hooks/useUserData"
import { UserData } from "../pages/api/user"
import { ServiceContext } from "../pages/[id]/[slug]"
import { useRouter } from "next/router"

interface LoginProps {
  userData: UserData
    isEnglish: boolean
  isCampaignForNewUsersOnly: boolean
}

export const LoginComponent = ({
  formik,
  userData,
  isEnglish,
  isCampaignForNewUsersOnly
}: LoginProps & { formik: FormikContextType<{}> }) => {
  const baseUrl = process.env.NEXT_PUBLIC_HOST

  const { donationAmountService, recurringService } = useContext(ServiceContext)
  const { data: session, status: sessionLoadingStatus } = useSession()
  const { user } = useUserData()
  const [donationAmountContext, setDonationAmountContext] = useState(null)
  const [_, setRecurringContext] = useState(null)
  const router = useRouter()

  const updateFields = (userObj) => {
    // Now we update the formik Fields
    Object.keys(userObj).forEach(async (userField) => {
      try {
        // prefill user data except for payment method
        if (userField !== "payment_methods") {
          await formik.setFieldValue(userField, userObj[userField])
          await formik.setFieldTouched(userField, true)
        }
      } catch (error) {
        console.error("FormikFormError:", error)
        Sentry.captureException(error)
      }
    })
  }

  useEffect(() => {
    const subscription = donationAmountService.subscribe((state) => {
      setDonationAmountContext(state.context)
    })

    return subscription.unsubscribe
  }, [donationAmountService])

  useEffect(() => {
    const subscription = recurringService.subscribe((state) => {
      setRecurringContext(state.context)
    })

    return subscription.unsubscribe
  }, [recurringService])

  useEffect(() => {
    if ((user || userData) && !isCampaignForNewUsersOnly) {
      updateFields(userData || user)
    }
  }, [user, userData])

  const callbackUrl = new URL(
    `${baseUrl}/${router.query.id}/${router.query.slug}`
  )

  if (
    donationAmountContext?.donationAmount ||
    donationAmountContext?.donationAmountSelected
  ) {
    callbackUrl.searchParams.append(
      "amount",
      donationAmountContext.donationAmount ||
        donationAmountContext.donationAmountSelected
    )
  }

  if (!session && sessionLoadingStatus !== "loading") {
    return (
      <div className="login">
        <p id="login-text" className="ss5 mb-4" data-testid="login-button">
          {isEnglish ?
            "Already have an account?" : 
            "¿Ya tienes una cuenta?"
          }{" "}
          <span
            id="login-btn"
            onClick={() =>
              // Passing in the provier id bypasses the interstital page
              // where users can select their sign in method
              signIn("azure-ad-b2c", {
                callbackUrl: callbackUrl.href
              })
            }
          >
            {isEnglish ?
            "Sign in" : 
            "Inicie sesión"
            }
          </span>{" "}
          {isEnglish ?
            "to prefill your account details and choose from saved payment methods." : 
            "para completar los detalles de su cuenta y elejir entre los métodos de pago guardados."
          }{" "}
        </p>
      </div>
    )
  } else {
    return null
  }
}

export const Login = connect<LoginProps>(LoginComponent)
